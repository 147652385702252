import React, {useState, useEffect, Suspense} from 'react';
import "./../assets/scss/App.scss";
import 'regenerator-runtime/runtime';

import { AnimatePresence } from "framer-motion";
import { Routes, Route, Link } from "react-router-dom";
import { motion } from "framer-motion";

import Home from "./pages/Home";
import About from "./pages/About";

import gsap, { Power3 } from 'gsap'

import ImageUploading from 'react-images-uploading';

import { Alchemy, Network, fromHex } from "alchemy-sdk";

function App() {

  const [isLoaded, setIsLoaded] = useState(false);
  const [isPageLoaded, setIsPageLoaded] = useState(false); //this helps

  const [currentAccount, setCurrentAccount] = useState(null);
  const [currentAccountName, setCurrentAccountName] = useState(null);


  const [images, setImages] = React.useState([]);
  const maxNumber = 69;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };

  const checkENS = async () => {
  try {
    const ensContractAddress = "0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85";
    const ens = await alchemy.nft.getNftsForOwner(currentAccount, {
      contractAddresses: [ensContractAddress],
    });
    setEnsNFT(ens);
    setCurrentAccountName(ens.ownedNfts[0].title)

    checkNNS()
  } catch (error) {
    console.log(error);
  }
}

const checkNNS = async () => {
  try {
    const nnsContractAddress = "0x4af84535625fE40990bfB35019B944a9933f7593";
    const nns = await alchemy.nft.getNftsForOwner(currentAccount, {
      contractAddresses: [nnsContractAddress],
    });

    console.log('NNS', nns)

    // setNnsNFT(nns);
    setCurrentAccountName(nns.ownedNfts[0].title+'.⌐◨-◨')
  } catch (error) {
    console.log(error);
  }
}

const checkIfWalletIsConnected = async () => {
  try {
    const { ethereum } = window;

    if (!ethereum) {
      console.log('Make sure you have MetaMask!');
      return;
    } else {
      // console.log('We have the ethereum object', ethereum);

      /*
      * Check if we're authorized to access the user's wallet
      */
      const accounts = await ethereum.request({ method: 'eth_accounts' });

      /*
      * User can have multiple authorized accounts, we grab the first one if its there!
      */
      if (accounts.length !== 0) {
        const account = accounts[0];
        // console.log('Found an authorized account:', account);
        setCurrentAccount(account);
        // setCurrentAccount("0x7cdF507C5439D528769d7a3eADC38CE070058a1E"); // amiyoko
        var tmpAccountName = account.substring(0, 5)+'...'+account.substr(account.length - 3)
        setCurrentAccountName(tmpAccountName)

        checkENS()
        checkNoundry()

        //
        // setTimeout(() => {
        //   checkENS();
        // }, "500")

      } else {
        // console.log('No authorized account found');
      }
    }
  } catch (error) {
    console.log(error);
  }
};

const checkNetwork = async () => {
  try {
    if (window.ethereum.networkVersion !== '1') {
      console.log("Please connect to Ethereum Mainnet!")
    }
  } catch(error) {
    console.log(error)
  }
}

// async function disconnect() {
//   try {
//     console.log(window.ethereum)
//     // await window.ethereum..disconnect()
//   } catch (error) {
//     console.log(error);
//   }
// }

const renderWeb3 = () => {
  // if (isLoading) {
  //   return <LoadingIndicator />;
  // }

  if (!currentAccount) {
    return (
      <>
      <button
      id="connectWallet"
      onClick={connectWalletAction}
      >
      connect<span> metamask</span>
      </button>
      </>
    );
  } else {
    return (
      <>
      {currentAccountName}
      </>
    )
  }
};

const connectWalletAction = async () => {
  try {
    const { ethereum } = window;

    if (!ethereum) {
      window.open('https://metamask.io/');
      return;
    }

    /*
    * Fancy method to request access to account.
    */
    const accounts = await ethereum.request({
      method: 'eth_requestAccounts',
    });

    /*
    * Boom! This should print out public address once we authorize Metamask.
    */
    console.log('Connected', accounts[0]);
    setCurrentAccount(accounts[0]);

    // setCurrentAccount("0x7cdF507C5439D528769d7a3eADC38CE070058a1E"); // amiyoko
    var tmpAccountName = account[0].substring(0, 5)+'...'+account[0].substr(account[0].length - 3)
    setCurrentAccountName(tmpAccountName)

    checkENS()

  } catch (error) {
    console.log(error);
  }
};
/*
* This runs our function when the page loads.
*/
useEffect(() => {
  // setIsLoading(true)
  checkIfWalletIsConnected()
  checkNetwork()

  if(currentAccount !== null) {
    checkENS()
  }

}, []);

useEffect(() => {
    setIsLoaded(true);
}, []);

useEffect(() => {
    if (isLoaded) {


    }
}, [isLoaded]);

function submitPhotoContainer() {

  document.getElementById("app").classList.add("noscroll")

  var anim = new gsap.timeline({pause:true})
  .to(document.getElementById("nav"), { duration: .2, ease: "expo", top: '-50px' })
  .to(document.getElementById("uploadMenu"), { duration: .2, ease: "expo", top: '0' })
  .to(document.getElementById("submitPhotoContainer"), { duration: .7, ease: "expo", top: '50px' })
  anim.play()
}

function closeUpload() {

  document.getElementById("app").classList.remove("noscroll")

  var anim = new gsap.timeline({pause:true})
  .to(document.getElementById("uploadMenu"), { duration: .2, ease: "expo", top: '-100px' })
  .to(document.getElementById("nav"), { duration: .7, ease: "expo", top: '0' })
  .to(document.getElementById("submitPhotoContainer"), { duration: .7, ease: "expo", top: '100%' })
  anim.play()
}


function closeSingle() {

  document.getElementById("app").classList.remove("noscroll")

  const elements = document.querySelectorAll('[current="true"');

  var current;

  elements.forEach(element => {
    current = element
  });

  var anim = new gsap.timeline({pause:true})

  .to(document.getElementById("singleMenu"), { duration: .2, ease: "expo", top: '-100px' })

  .to(current, { duration: .2, ease: "expo", top: '100%' })

  .to(document.getElementById("singlePhotoContainer"), { duration: .7, ease: "expo", top: '100%' })

  .to(document.getElementById("single"), { duration: .7, ease: "expo", top: '100%' })

  .to(document.getElementById("nav"), { duration: .7, ease: "expo", top: '0' })

  anim.play()
  document.getElementById('download').setAttribute('href', '')

  current.setAttribute('current', false)

}

function pageChange(page) {

  if(window.innerWidth !== undefined) {
    var w = window.innerWidth;
  } else {
    var w = document.documentElement.clientWidth;
  }

  if(page === "about") {
    // if(w <= 640) {
    //   var anim = new gsap.timeline({pause:true})
    //   .to(document.getElementById("about"), { duration: .2, ease: "expo", top: '-50px' })
    //   .to(document.getElementById("home"), { duration: .2, ease: "expo", top: '50px' })
    //   anim.play()
    // } else {
      var anim = new gsap.timeline({pause:true})
      .to(document.getElementById("about"), { duration: .2, ease: "expo", top: '-50px' })
      .to(document.getElementById("home"), { duration: .2, ease: "expo", top: '0' })
      anim.play()
    // }
  }

  if(page === "home") {
  //   if(w <= 640) {
  //     var anim = new gsap.timeline({pause:true})
  //     .to(document.getElementById("home"), { duration: .2, ease: "expo", top: '-50px' })
  //     .to(document.getElementById("about"), { duration: .2, ease: "expo", top: '50px' })
  //     anim.play()
  //   } else {
      var anim = new gsap.timeline({pause:true})
      .to(document.getElementById("home"), { duration: .2, ease: "expo", top: '-50px' })
      .to(document.getElementById("about"), { duration: .2, ease: "expo", top: '0' })
      anim.play()
    // }
  }

}

      return (
        <div id="app" className="app">

        <div id="uploadMenu">
          <div className="closeUploadContainer"><a id="close" onClick={closeUpload}>cancel upload</a></div>
          <div className="walletContainer">
          {renderWeb3()}
          </div>
        </div>

        <div id="singleMenu">
          <div className="closeContainer"><a id="close" onClick={closeSingle}>index</a></div>
          <div className="downloadContainer"><a href="" id="download" className="button" download target="_blank">download</a></div>
        </div>

        <header>
          <div>
            <h1><Link to="/"><img className="logo" src="/logo.png" alt=""/>CC0 Space Image Bank</Link></h1>
            <nav id="nav">
            <Link id="about" to="/about" onClick={() => { pageChange('about');}}>About</Link>
            <Link id="home" to="/" onClick={() => { pageChange('home');}}>Home</Link>
            <button id="submit" onClick={submitPhotoContainer}>Submit<span> a photo</span></button>
            </nav>
          </div>
          </header>

          <main>
            <Routes>
              <Route path="/" element={<Home/>}/>
              <Route path="/about" element={<About/>} />
            </Routes>
          </main>

          <footer>
           cc0 licence <div className="poweredBy">powered by <a href="https://nouns.wtf" title="Nouns" target="_blank" rel="nofollow"><img src="noggles.svg" alt="Nouns"/></a></div>
          </footer>

        <div id="submitPhotoContainer">

        <p>You can connect your Metamask wallet to sign the photos you are submitting</p>

        <ImageUploading
          multiple
          value={images}
          onChange={onChange}
          maxNumber={maxNumber}
          dataURLKey="data_url"
        >
          {({
            imageList,
            onImageUpload,
            onImageRemoveAll,
            onImageUpdate,
            onImageRemove,
            isDragging,
            dragProps,
          }) => (
            // write your building UI
            <div className="upload__image-wrapper">
              <button
              className="uploadArea"
                style={isDragging ? { color: 'red' } : undefined}
                onClick={onImageUpload}
                {...dragProps}
              >
                Click or Drop here
              </button>
              <button onClick={onImageRemoveAll}>Remove all images</button>
              {imageList.map((image, index) => (
                <div key={index} className="image-item">
                  <img src={image['data_url']} alt="" width="100" />
                  <div className="image-item__btn-wrapper">
                    <button onClick={() => onImageUpdate(index)}>Update</button>
                    <button onClick={() => onImageRemove(index)}>Remove</button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </ImageUploading>
        </div>

        </div>
      )
}

export default App;
