import React, { Component } from 'react';
import { motion } from "framer-motion";

import Medias from '../medias'

  function submit() {
    document.getElementById('submit').click();
  }

class About extends Component {

  render() {
    return (
      <>

      <div className="text">
        <div className="textContainer">
          <h2>About</h2>

          <p>
          Created by <a href="" title="Cosmos Astro Art" target="_blank" rel="nofollow">Cosmos Astro Art</a> and Sponsored by The Nouns DAO, the cc0 Space image Bank is an image repository intented for artists to use and appropriate in their work.
          </p>

          <p>
          All the images in this website are free to use and repurpose in any way under a cc0 licence. Nouns are an experimental attempt to improve the formation of on-chain avatar communities.<br/>
          While projects such as Cryptopunks have attempted to bootstrap digital community and identity, Nouns attempt to bootstrap identity, community, governance, and a treasury that can be used by the community.<br/>
          More info at <a href="https://nouns.wtf" title="Nouns" target="_blank" rel="nofollow">nouns.wtf</a>
          </p>

          <p>
          Want to share your images? Feel free to <a onClick={submit}>submit</a> or reach out by <a href="mailto:cosmosastroart@gmail.com">email</a>.
          </p>

          <p>
          All the images in this website are free to use and/or repurpose in any way under <a href="https://creativecommons.org/share-your-work/public-domain/cc0/" title="Nouns" target="_blank" rel="nofollow">CC0 licence</a>.
          </p>
        </div>
      </div>



      </>
    );
  }

}

export default About;
