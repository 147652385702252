import React, { useEffect, useState } from 'react';
import gsap, { Power3 } from 'gsap'
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function Medias() {
    const [medias, setMedias] = useState([]);
    const [current, setCurrent] = useState(null);
    const [currentTags, setCurrentTags] = useState(' ');

    const [author, setAuthor] = useState("0x350c603508524952244042AB5563a4dA4dc4488d")

    useEffect(() => {
        async function loadMedias() {
            const response = await fetch('https://cc0images.wtf/wp-json/wp/v2/media/?per_page=100');
            if(!response.ok) {
                return;
            }

            const medias = await response.json();
            console.log(medias)
            setMedias(medias);
        }

        loadMedias();
   }, [])

   function openSingle(i) {

     var currentTags

     document.getElementById("app").classList.add("noscroll")

     setCurrent(i)

     document.getElementById("single-"+i).setAttribute('current', true)

     var anim = new gsap.timeline({pause:true})

     .to(document.getElementById("nav"), { duration: .7, ease: "expo", top: '-50px' })

     .to(document.getElementById("singleMenu"), { duration: .2, ease: "expo", top: '0' })

     .to(document.getElementById("single"), { duration: .7, ease: "expo", top: '0' })

     .to(document.getElementById("single-"+i), { duration: .5, ease: "expo", top: '0' })


     .to(document.getElementById("singlePhotoContainer"), { duration: .7, ease: "expo", top: '0' })
     anim.play()

     document.getElementById('download').setAttribute('href', medias[i].guid.rendered)

     let tag = medias[i].tags[0]
     console.log(tag)
     if(tag == 4) {
       tag = "Sun"
     }
     if(tag == 3) {
       tag = "Moon"
     }
     if(tag === undefined) {
       tag = ""
     }
     document.getElementById('tags').innerHTML = tag

   }

   function ibeforeLoad() {
     console.log('show loader')
   }

   function iafterLoad() {
     console.log('loaded')
   }

   function navImage(next) {

     document.getElementById('tags').innerHTML = ''

     if(next == -1) {
       next = medias.length
     }

     if(next == medias.length) {
       next = 0
     }

     document.getElementById("single-"+current).setAttribute('current', false)
     document.getElementById("single-"+next).setAttribute('current', true)

     var anim = new gsap.timeline({pause:true})
     .to(document.getElementById("single-"+current), { duration: .5, ease: "expo", top: '100%' })
     .to(document.getElementById("single-"+next), { duration: .5, ease: "expo", top: '0' })

     anim.play()

     setCurrent(next)
     document.getElementById('download').setAttribute('href', medias[next].guid.rendered)

     let tag = medias[next].tags[0]
     console.log(tag)
     if(tag == 4) {
       tag = "Sun"
     }
     if(tag == 3) {
       tag = "Moon"
     }
     if(tag === undefined) {
       tag = ""
     }
     document.getElementById('tags').innerHTML = tag

   }

  return (
    <>

    <div className="image-grid">
        {medias.map((media, index) => (
          <li className="image-grid__item" key={index}>
            <a href="#" className="grid-item" onClick={() => { openSingle(index); }}>
              <div className="grid-item__image" style={{ backgroundImage: media.media_details?.sizes?.medium_large?.source_url ? 'url(' + media.media_details.sizes.medium_large.source_url + ')' : 'none' }}></div>
            </a>
          </li>
        ))}
    </div>

    <div id="single">
    {medias.map((media, index) => (
    <div key={index} id={"single-"+index} className="singlePhotoContainer">
      <LazyLoadImage
        beforeLoad={() => ibeforeLoad()}
        afterLoad={() => iafterLoad()}
        src={media.media_details?.sizes?.medium_large?.source_url}
      />
      </div>
    ))}

    <div id="tags">{currentTags}</div>
    <div id="author"><a href={"https://etherscan.io/address/"+author} target="_blank" rel="nofollow">{author.substring(0, 5)+'...'+author.substr(author.length - 3)}</a></div>
    <a id="previous" onClick={() => { navImage(current - 1);}}>previous</a>
    <a id="next" onClick={() => { navImage(current + 1);}}>next</a>
    </div>

    </>
 );
}
