import React, { Component } from 'react';
import { motion } from "framer-motion";

import Medias from '../medias'

class Home extends Component {

  render() {
    return (
      <>
      <div className="text">
        <div className="textContainer">
          <h2>CC0 images, WTF?</h2>

          {
            // <p>
            // Sponsored by the <a href="https://nouns.wtf" title="Nouns" target="_blank" rel="nofollow">Nouns DAO</a>, the CC0 image Bank is an image repository
            // intented for artists to use and appropriate in their work.
            // </p>
          }

          <p>
          All the images in this website are free to use and/or repurpose in any way under <a href="https://creativecommons.org/share-your-work/public-domain/cc0/" title="Nouns" target="_blank" rel="nofollow">CC0 licence</a>.
          </p>
        </div>
      </div>

      <Medias/>


      </>
    );
  }

}

const pageVariants = {
initial: {
  opacity: 0,
  y: "-100vh",
},
in: {
  opacity: 1,
  y: 0,
  scale: 1
},
out: {
  opacity: 0,
  y: "100vh",
}
};

const pageTransition = {
  type: "tween",
  ease: "anticipate",
  duration: 1
};

export default Home;
